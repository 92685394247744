var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useLoading } from '@/hooks/useLoading';
import { useStsStore } from '@/store/sts';
import { useUserStore } from '@/store/user';
import { Md5 } from 'ts-md5';
import { fileToBlob } from './fileToBlob';
export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploader',
  props: {
    value: String
  },
  emits: ['update:value'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const store = useStsStore();
    const userStore = useUserStore();
    const [loading, handler] = useLoading();
    const beforeUpload = opt => __awaiter(this, void 0, void 0, function* () {
      return handler(() => __awaiter(this, void 0, void 0, function* () {
        const client = yield store.getOssClient();
        const bolb = yield fileToBlob(opt.file.file);
        const filePath = `template/${userStore.userInfo.userIdMd5}/${Md5.hashStr(opt.file.file.name)}.${opt.file.file.type}`;
        const res = yield client.put(filePath, bolb);
        if (res.url) {
          emit('update:value', `https://computed-cdn.langtaoshuzi.com/${filePath}`);
        }
        return false;
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_avatar = _resolveComponent("n-avatar");
      const _component_n_upload = _resolveComponent("n-upload");
      return _openBlock(), _createBlock(_component_n_upload, {
        loading: _unref(loading),
        name: "value",
        accept: ".png,.jpg",
        "on-before-upload": beforeUpload
      }, {
        default: _withCtx(() => [_createVNode(_component_n_avatar, {
          size: 196,
          src: __props.value
        }, {
          fallback: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" 请选择 ")])),
          _: 1
        }, 8, ["src"])]),
        _: 1
      }, 8, ["loading"]);
    };
  }
});