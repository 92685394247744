import { webApi } from '@/utils/api';

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiResultObject {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: object;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface UserLoginDTO {
  username: string;
  password: string;
}

export interface ApiResultLoginResultVo {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: LoginResultVo;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface LoginResultVo {
  tenantList?: Tenants[];
  token?: string;
}

/** tenants */
export interface Tenants {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * App应用名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 微信开放平台appid
   * @minLength 0
   * @maxLength 255
   */
  wxOpenAppId?: string;
  /**
   * 微信开放平台secret
   * @minLength 0
   * @maxLength 255
   */
  wxOpenAppSecret?: string;
  /**
   * 苹果支付的bundleid
   * @minLength 0
   * @maxLength 255
   */
  appleBundleId: string;
  /**
   * 苹果支付验签预定义密钥
   * @minLength 0
   * @maxLength 255
   */
  applePaySecret: string;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** sa_template_category */
export interface SaTemplateCategory {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 分类名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultCredentials {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: Credentials;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface Credentials {
  securityToken?: string;
  accessKeySecret?: string;
  accessKeyId?: string;
  expiration?: string;
}

/** sa_template */
export interface SaTemplate {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 模版名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 状态栏颜色
   * @minLength 0
   * @maxLength 255
   */
  statusColor: string;
  /** 模版封面 */
  pic: string;
  /**
   * 是否vip模版
   * @format int32
   */
  isVip?: number;
  /**
   * 模版分类
   * @format int64
   */
  categoryId?: number;
  /**
   * 二级页面背景色
   * @minLength 0
   * @maxLength 20
   */
  twoBgColor: string;
  /** 背景色 */
  twoColor?: string;
  /** 主题色 */
  themeColor?: string;
  /**
   * 手机键盘区高度
   * @format double
   */
  boardMobileHeight?: number;
  /**
   * 平板键盘区高度
   * @format double
   */
  boardIpadHeight?: number;
  /**
   * 手机键盘区左右边距
   * @format double
   */
  boardMobileX?: number;
  /**
   * 手机键盘区上下边距
   * @format double
   */
  boardMobileY?: number;
  /**
   * 平板键盘区左右边距
   * @format double
   */
  boardIpadX?: number;
  /**
   * 平板键盘区上下边距
   * @format double
   */
  boardIpadY?: number;
  /**
   * 上下架状态，默认0下架,点击自行上架
   * @format int64
   */
  decoration?: number;
  /** 所有资源列表字符串 */
  resource: string;
  /**
   * 上下架状态，默认0下架,点击自行上架
   * @format int64
   */
  status?: number;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** sa_order_template */
export interface SaOrderTemplate {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 档位列表
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 特惠
   * @minLength 0
   * @maxLength 255
   */
  tips?: string;
  /**
   * 是否连续包月
   * @format int32
   */
  isMonthly?: number;
  /**
   * 是否测试档位
   * @format int32
   */
  isSandBox?: number;
  /**
   * 会员有效期(天)
   * @format int64
   */
  day?: number;
  /**
   * 优惠前的价格(分)
   * @format int64
   */
  money?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  iosFavorableProductId?: string;
  /**
   * 优惠之后的价格
   * @format int64
   */
  preferentialPrice?: number;
  /**
   * iOS内购标识
   * @minLength 0
   * @maxLength 255
   */
  iosProductId: string;
  /**
   * 登录的租户id
   * @format int64
   */
  tenantId?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** ad_language */
export interface AdLanguage {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  label: string;
  /**
   * 字段标识
   * @minLength 0
   * @maxLength 255
   */
  field: string;
  /**
   * 语言标识
   * @minLength 0
   * @maxLength 255
   */
  lang: string;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** sa_dict */
export interface SaDict {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 父级id
   * @format int64
   */
  pid?: number;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  /**
   * 数据类型支持 dict array
   * @minLength 0
   * @maxLength 255
   */
  type?: string;
  label: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  value: string;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** sa_audio_category */
export interface SaAudioCategory {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 分类名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 登录的租户id
   * @format int64
   */
  tenantId?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultSaAudioCategory {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaAudioCategory;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

/** sa_audio */
export interface SaAudio {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 音频名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 是否vip可用
   * @format int32
   */
  isVip?: number;
  /**
   * 分类
   * @format int64
   */
  categoryId?: number;
  /** 音频地址 */
  resource: string;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** ad_user */
export interface AdUser {
  /**
   * 用户id
   * @format int64
   */
  id: number;
  /**
   * 用户账号
   * @minLength 0
   * @maxLength 255
   */
  username: string;
  /**
   * 用户密码
   * @minLength 0
   * @maxLength 255
   */
  password: string;
  /**
   * 登录的租户id
   * @format int64
   */
  tenantId: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultAdUser {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: AdUser;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListTenants {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: Tenants[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaTemplateCategory {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaTemplateCategory[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaTemplate {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaTemplate[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultSaTemplate {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaTemplate;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultPageSaUser {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: PageSaUser;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface OrderItem {
  column?: string;
  asc?: boolean;
}

export interface PageSaUser {
  records?: SaUser[];
  /** @format int64 */
  total?: number;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  current?: number;
  orders?: OrderItem[];
  optimizeCountSql?: PageSaUser;
  searchCount?: PageSaUser;
  optimizeJoinOfCountSql?: boolean;
  /** @format int64 */
  maxLimit?: number;
  countId?: string;
  /**
   * @deprecated
   * @format int64
   */
  pages?: number;
}

/** sa_user */
export interface SaUser {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 用户昵称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * Vip到期时间
   * @format date-time
   */
  vipExpiration?: string;
  /**
   * 是否测试账号(1是0否)
   * @format int32
   */
  isSandBox?: number;
  /**
   * 苹果登录后唯一账户id
   * @minLength 0
   * @maxLength 255
   */
  appleId?: string;
  /**
   * 微信登录后的union_id
   * @minLength 0
   * @maxLength 255
   */
  unionId?: string;
  /**
   * 微信登录后的openid
   * @minLength 0
   * @maxLength 255
   */
  openId?: string;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId?: number;
  /** IOS设备号 */
  iosId?: string;
  /** 安卓设备号 */
  androidId?: string;
  /**
   * 语言
   * @minLength 0
   * @maxLength 255
   */
  language?: string;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultListSaOrderTemplate {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaOrderTemplate[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultPageAdLanguage {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: PageAdLanguage;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface PageAdLanguage {
  records?: AdLanguage[];
  /** @format int64 */
  total?: number;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  current?: number;
  orders?: OrderItem[];
  optimizeCountSql?: PageAdLanguage;
  searchCount?: PageAdLanguage;
  optimizeJoinOfCountSql?: boolean;
  /** @format int64 */
  maxLimit?: number;
  countId?: string;
  /**
   * @deprecated
   * @format int64
   */
  pages?: number;
}

export interface ApiResultListSaDict {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaDict[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaAudioCategory {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaAudioCategory[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaAudio {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaAudio[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

type GetApiResult<T> = T extends { data?: infer R } ? R : T;

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://localhost:7002
 */

/**
 * No description
 *
 * @tags 用户管理
 * @name Login
 * @summary 用户登录
 * @request POST:/ad/user/login
 */
export const postAdUserLogin = (data: UserLoginDTO) => {
  return webApi.post<GetApiResult<ApiResultLoginResultVo>>(`/ad/user/login`, data);
};
/**
 * No description
 *
 * @tags 模版分类管理
 * @name Remove
 * @summary 删除分类
 * @request POST:/ad/templateCategory/remove
 */
export const postAdTemplateCategoryRemove = (data: SaTemplateCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/templateCategory/remove`, data);
};
/**
 * No description
 *
 * @tags 模版分类管理
 * @name Edit
 * @summary 修改分类
 * @request POST:/ad/templateCategory/edit
 */
export const postAdTemplateCategoryEdit = (data: SaTemplateCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/templateCategory/edit`, data);
};
/**
 * No description
 *
 * @tags 模版分类管理
 * @name Add
 * @summary 新增分类
 * @request POST:/ad/templateCategory/add
 */
export const postAdTemplateCategoryAdd = (data: SaTemplateCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/templateCategory/add`, data);
};
/**
 * No description
 *
 * @tags 资源管理
 * @name Sts
 * @summary 文件上传授权
 * @request POST:/ad/template/sts
 */
export const postAdTemplateSts = () => {
  return webApi.post<GetApiResult<ApiResultCredentials>>(`/ad/template/sts`, {});
};
/**
 * No description
 *
 * @tags 资源管理
 * @name Remove1
 * @summary 删除模版
 * @request POST:/ad/template/remove
 */
export const postAdTemplateRemove = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/remove`, data);
};
/**
 * No description
 *
 * @tags 资源管理
 * @name Edit1
 * @summary 修改模版
 * @request POST:/ad/template/edit
 */
export const postAdTemplateEdit = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/edit`, data);
};
/**
 * No description
 *
 * @tags 资源管理
 * @name Add1
 * @summary 新增模版
 * @request POST:/ad/template/add
 */
export const postAdTemplateAdd = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/add`, data);
};
/**
 * No description
 *
 * @tags 订单模版管理
 * @name Remove2
 * @summary 删除档位
 * @request POST:/ad/orderTemplate/remove
 */
export const postAdOrderTemplateRemove = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/remove`, data);
};
/**
 * No description
 *
 * @tags 订单模版管理
 * @name Edit2
 * @summary 修改档位
 * @request POST:/ad/orderTemplate/edit
 */
export const postAdOrderTemplateEdit = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/edit`, data);
};
/**
 * No description
 *
 * @tags 订单模版管理
 * @name Add2
 * @summary 新增档位
 * @request POST:/ad/orderTemplate/add
 */
export const postAdOrderTemplateAdd = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/add`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Remove3
 * @summary 删除语言
 * @request POST:/ad/lang/remove
 */
export const postAdLangRemove = (data: AdLanguage) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/lang/remove`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Edit3
 * @summary 修改语言
 * @request POST:/ad/lang/edit
 */
export const postAdLangEdit = (data: AdLanguage) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/lang/edit`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Add3
 * @summary 新增语言
 * @request POST:/ad/lang/add
 */
export const postAdLangAdd = (data: AdLanguage) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/lang/add`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Remove4
 * @summary 删除数据
 * @request POST:/ad/dict/remove
 */
export const postAdDictRemove = (data: SaDict) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/dict/remove`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Edit4
 * @summary 修改数据
 * @request POST:/ad/dict/edit
 */
export const postAdDictEdit = (data: SaDict) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/dict/edit`, data);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name Add4
 * @summary 新增数据
 * @request POST:/ad/dict/add
 */
export const postAdDictAdd = (data: SaDict) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/dict/add`, data);
};
/**
 * No description
 *
 * @tags 音频分类管理
 * @name Remove5
 * @summary 删除分类
 * @request POST:/ad/audioCategory/remove
 */
export const postAdAudioCategoryRemove = (data: SaAudioCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audioCategory/remove`, data);
};
/**
 * No description
 *
 * @tags 音频分类管理
 * @name Edit5
 * @summary 修改分类
 * @request POST:/ad/audioCategory/edit
 */
export const postAdAudioCategoryEdit = (data: SaAudioCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audioCategory/edit`, data);
};
/**
 * No description
 *
 * @tags 音频分类管理
 * @name ById
 * @summary 根据id获取分类
 * @request POST:/ad/audioCategory/byId
 */
export const postAdAudioCategoryById = (data: SaAudioCategory) => {
  return webApi.post<GetApiResult<ApiResultSaAudioCategory>>(`/ad/audioCategory/byId`, data);
};
/**
 * No description
 *
 * @tags 音频分类管理
 * @name Add5
 * @summary 新增分类
 * @request POST:/ad/audioCategory/add
 */
export const postAdAudioCategoryAdd = (data: SaAudioCategory) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audioCategory/add`, data);
};
/**
 * No description
 *
 * @tags 音频管理
 * @name Remove6
 * @summary 删除模版
 * @request POST:/ad/audio/remove
 */
export const postAdAudioRemove = (data: SaAudio) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audio/remove`, data);
};
/**
 * No description
 *
 * @tags 音频管理
 * @name Edit6
 * @summary 修改音频
 * @request POST:/ad/audio/edit
 */
export const postAdAudioEdit = (data: SaAudio) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audio/edit`, data);
};
/**
 * No description
 *
 * @tags 音频管理
 * @name Add6
 * @summary 新增模版
 * @request POST:/ad/audio/add
 */
export const postAdAudioAdd = (data: SaAudio) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/audio/add`, data);
};
/**
 * No description
 *
 * @tags 用户管理
 * @name Info
 * @summary 获取信息
 * @request GET:/ad/user/info
 */
export const getAdUserInfo = () => {
  return webApi.get<GetApiResult<ApiResultAdUser>>(`/ad/user/info`, {});
};
/**
 * No description
 *
 * @tags 应用租户管理
 * @name List
 * @summary 获取全部租户列表
 * @request GET:/ad/tenant/list
 */
export const getAdTenantList = () => {
  return webApi.get<GetApiResult<ApiResultListTenants>>(`/ad/tenant/list`, {});
};
/**
 * No description
 *
 * @tags 模版分类管理
 * @name List1
 * @summary 获取全部分类
 * @request GET:/ad/templateCategory/list
 */
export const getAdTemplateCategoryList = () => {
  return webApi.get<GetApiResult<ApiResultListSaTemplateCategory>>(`/ad/templateCategory/list`, {});
};
/**
 * No description
 *
 * @tags 资源管理
 * @name List2
 * @summary 获取全部模版
 * @request GET:/ad/template/list
 */
export const getAdTemplateList = () => {
  return webApi.get<GetApiResult<ApiResultListSaTemplate>>(`/ad/template/list`, {});
};
/**
 * No description
 *
 * @tags 资源管理
 * @name GetById
 * @summary 根据指定id获取模版
 * @request GET:/ad/template/get
 */
export const getAdTemplateGet = (query: { id: string }) => {
  return webApi.get<GetApiResult<ApiResultSaTemplate>>(`/ad/template/get`, query);
};
/**
 * No description
 *
 * @tags 用户管理
 * @name Page
 * @summary 分页获取用户
 * @request GET:/ad/saUser/page
 */
export const getAdSaUserPage = (query: {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  pageIndex: number;
}) => {
  return webApi.get<GetApiResult<ApiResultPageSaUser>>(`/ad/saUser/page`, query);
};
/**
 * No description
 *
 * @tags 订单模版管理
 * @name List3
 * @summary 获取全部档位
 * @request GET:/ad/orderTemplate/list
 */
export const getAdOrderTemplateList = () => {
  return webApi.get<GetApiResult<ApiResultListSaOrderTemplate>>(`/ad/orderTemplate/list`, {});
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name List4
 * @summary 获取全部数据
 * @request GET:/ad/lang/page
 */
export const getAdLangPage = (query: {
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  pageIndex: number;
  field?: string;
  lang?: string;
}) => {
  return webApi.get<GetApiResult<ApiResultPageAdLanguage>>(`/ad/lang/page`, query);
};
/**
 * No description
 *
 * @tags 数据碎片
 * @name List5
 * @summary 获取全部数据
 * @request GET:/ad/dict/list
 */
export const getAdDictList = () => {
  return webApi.get<GetApiResult<ApiResultListSaDict>>(`/ad/dict/list`, {});
};
/**
 * No description
 *
 * @tags 音频分类管理
 * @name List6
 * @summary 获取全部分类
 * @request GET:/ad/audioCategory/list
 */
export const getAdAudioCategoryList = () => {
  return webApi.get<GetApiResult<ApiResultListSaAudioCategory>>(`/ad/audioCategory/list`, {});
};
/**
 * No description
 *
 * @tags 音频管理
 * @name List7
 * @summary 获取全部音频
 * @request GET:/ad/audio/list
 */
export const getAdAudioList = () => {
  return webApi.get<GetApiResult<ApiResultListSaAudio>>(`/ad/audio/list`, {});
};
