var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "FileUpload"
};
import { useLoading } from '@/hooks/useLoading';
import { useStsStore } from '@/store/sts';
import { useUserStore } from '@/store/user';
import { unref } from 'vue';
import { Md5 } from 'ts-md5';
import { fileToBlob } from './fileToBlob';
export default /*@__PURE__*/_defineComponent({
  __name: 'FileUpload',
  props: {
    name: String,
    value: String,
    accept: String
  },
  emits: ['update:value'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const store = useStsStore();
    const userStore = useUserStore();
    const [loading, handler] = useLoading();
    const props = __props;
    const beforeUpload = opt => __awaiter(this, void 0, void 0, function* () {
      return handler(() => __awaiter(this, void 0, void 0, function* () {
        const client = yield store.getOssClient();
        const bolb = yield fileToBlob(opt.file.file);
        const filePath = `${unref(props.name)}/${userStore.userInfo.userIdMd5}/${Md5.hashStr(opt.file.file.name)}.${opt.file.file.type}`;
        const res = yield client.put(filePath, bolb);
        if (res.url) {
          emit('update:value', `https://computed-cdn.langtaoshuzi.com/${filePath}`);
        }
        return false;
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_upload = _resolveComponent("n-upload");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_n_upload, {
        loading: _unref(loading),
        name: "file",
        accept: __props.accept,
        "on-before-upload": opt => beforeUpload(opt)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_button, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("点击上传")])),
          _: 1
        }), _createElementVNode("p", null, _toDisplayString(__props.value), 1)]),
        _: 1
      }, 8, ["loading", "accept", "on-before-upload"])]);
    };
  }
});