import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "line-scroll"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = ["textContent"];
const _hoisted_4 = {
  class: "board-box"
};
import { usePreviewManger } from '@/hooks/usePreviewManger';
import { DPI } from '@/types/enum';
import { useWindowSize } from '@vueuse/core';
import { computed, ref, unref } from 'vue';
import IconButton from '@/components/IconButton.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const lines = ref(['0+0=0']);
    const input = ref('0');
    const isNumber = value => /^[0-9]$/.test(value);
    const size = useWindowSize();
    const isIpad = computed(() => unref(size.width) > 600 * DPI);
    const {
      message,
      onLoaded
    } = usePreviewManger();
    const buttonSize = computed(() => {
      if (unref(isIpad)) {
        return {
          width: 161,
          height: 123,
          doubleHeight: 246
        };
      }
      return {
        width: 185.33333333333334,
        height: 142,
        doubleHeight: 284
      };
    });
    const buttons = computed(() => {
      return [{
        normal: unref(message).resource.c_1,
        down: unref(message).resource.c_2,
        pos: [0, 0],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: 'c'
      }, {
        normal: unref(message).resource.chu_1,
        down: unref(message).resource.chu_2,
        pos: [1, 0],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '/'
      }, {
        normal: unref(message).resource.x_1,
        down: unref(message).resource.x_2,
        pos: [2, 0],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: 'x'
      }, {
        normal: unref(message).resource.delete_1,
        down: unref(message).resource.delete_2,
        pos: [3, 0],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: 'del'
      }, {
        normal: unref(message).resource.n1_1,
        down: unref(message).resource.n1_2,
        pos: [0, 1],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '1'
      }, {
        normal: unref(message).resource.n2_1,
        down: unref(message).resource.n2_2,
        pos: [1, 1],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '2'
      }, {
        normal: unref(message).resource.n3_1,
        down: unref(message).resource.n3_2,
        pos: [2, 1],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '3'
      }, {
        normal: unref(message).resource.jia_1,
        down: unref(message).resource.jia_2,
        pos: [3, 1],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '+'
      }, {
        normal: unref(message).resource.n4_1,
        down: unref(message).resource.n4_2,
        pos: [0, 2],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '4'
      }, {
        normal: unref(message).resource.n5_1,
        down: unref(message).resource.n5_2,
        pos: [1, 2],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '5'
      }, {
        normal: unref(message).resource.n6_1,
        down: unref(message).resource.n6_2,
        pos: [2, 2],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '6'
      }, {
        normal: unref(message).resource.jian_1,
        down: unref(message).resource.jian_2,
        pos: [3, 2],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '+'
      }, {
        normal: unref(message).resource.n7_1,
        down: unref(message).resource.n7_2,
        pos: [0, 3],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '7'
      }, {
        normal: unref(message).resource.n8_1,
        down: unref(message).resource.n8_2,
        pos: [1, 3],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '8'
      }, {
        normal: unref(message).resource.n9_1,
        down: unref(message).resource.n9_2,
        pos: [2, 3],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '9'
      }, {
        normal: unref(message).resource.eq_1,
        down: unref(message).resource.eq_2,
        pos: [3, 3],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        doubleHeight: unref(buttonSize).doubleHeight,
        value: '='
      }, {
        normal: unref(message).resource.m_1,
        down: unref(message).resource.m_2,
        pos: [0, 4],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: 'm'
      }, {
        normal: unref(message).resource.n0_1,
        down: unref(message).resource.n0_2,
        pos: [1, 4],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '0'
      }, {
        normal: unref(message).resource.d_1,
        down: unref(message).resource.d_2,
        pos: [2, 4],
        width: unref(buttonSize).width,
        height: unref(buttonSize).height,
        value: '.'
      }];
    });
    const lastNumberIsFloat = input => {
      for (let i = input.length - 1; i >= 0; i -= 1) {
        if (['/', '*', '+', '-'].includes(input[i])) return false;
        if (input[i] === '.') return true;
      }
      return false;
    };
    const handleKeyDown = value => {
      if (value === 'm') return;
      if (value === '=') {
        // eslint-disable-next-line no-eval
        lines.value.push(`${input.value}=${Math.round(eval(input.value) * 100) / 100}`);
        input.value = '';
        return;
      }
      if (value === 'c') {
        input.value = '0';
        return;
      }
      if (value === 'del') {
        input.value = input.value.substring(0, input.value.length - 1);
        return;
      }
      if (input.value.length === 0 && ['/', '*', '+', '-', '.'].includes(value)) {
        input.value = '0';
      }
      const lastValue = input.value[input.value.length - 1];
      if (['/', '*', '+', '-', '.'].includes(lastValue) && ['/', '*', '+', '-', '.'].includes(value)) {
        input.value = input.value.substring(0, input.value.length - 1);
      }
      // 最后一个数字是否包含小数 包含则不继续执行
      if (value === '.' && lastNumberIsFloat(input.value)) return;
      if (!isNumber(value) && lastValue === value) return;
      if (input.value === '0' && isNumber(value)) {
        input.value = value;
        return;
      }
      input.value += value;
    };
    return (_ctx, _cache) => {
      const _component_BoardButton = _resolveComponent("BoardButton");
      return _unref(onLoaded) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["preview", {
          'isIpad': isIpad.value
        }]),
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.bg})`
        })
      }, [_createElementVNode("div", {
        class: "header",
        style: _normalizeStyle({
          backgroundImage: `url(${isIpad.value ? _unref(message).resource.top_bg_1 : _unref(message).resource.top_bg})`
        })
      }, [_createVNode(IconButton, {
        isIpad: isIpad.value,
        normal: _unref(message).resource.t_l_1,
        down: _unref(message).resource.t_l_2
      }, null, 8, ["isIpad", "normal", "down"]), _createElementVNode("div", {
        class: "text",
        style: _normalizeStyle({
          color: !_unref(message).resource.top_bg_1 && !_unref(message).resource.top_bg ? _unref(message).themeColor : '#FFFFFF',
          textShadow: !_unref(message).resource.top_bg_1 && !_unref(message).resource.top_bg ? `` : `0px 1px 2px ${_unref(message).themeColor}`
        })
      }, "模版名称", 4), _createVNode(IconButton, {
        isIpad: isIpad.value,
        normal: _unref(message).resource.t_c_1,
        down: _unref(message).resource.t_c_2
      }, null, 8, ["isIpad", "normal", "down"]), _createVNode(IconButton, {
        isIpad: isIpad.value,
        normal: _unref(message).resource.t_s_1,
        down: _unref(message).resource.t_s_2,
        style: {
          "margin-left": "0.2rem"
        }
      }, null, 8, ["isIpad", "normal", "down"])], 4), _createElementVNode("div", {
        class: _normalizeClass(["content", {
          innerPadding: !!_unref(message).resource.history_bg
        }]),
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.history_bg})`,
          backgroundSize: `100% 100%`
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lines.value, (line, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "text",
          style: _normalizeStyle({
            color: `${_unref(message).twoColor}`
          }),
          key: `${line}_${index}`
        }, _toDisplayString(line), 5);
      }), 128))])]), _createElementVNode("div", {
        class: "result",
        style: _normalizeStyle({
          color: _unref(message).themeColor
        }),
        textContent: _toDisplayString(input.value)
      }, null, 12, _hoisted_3)], 6), _createElementVNode("div", {
        class: "keyboard",
        style: _normalizeStyle({
          backgroundImage: `url(${isIpad.value ? _unref(message).resource.f_bg_1 : _unref(message).resource.f_bg})`,
          height: isIpad.value ? `${(_unref(message).boardIpadHeight || 830) / 100 * _unref(DPI)}rem` : `${(_unref(message).boardMobileHeight || 830) / 100 * _unref(DPI)}rem`
        })
      }, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buttons.value, button => {
        return _openBlock(), _createBlock(_component_BoardButton, _mergeProps({
          key: button.down,
          ref_for: true
        }, button, {
          handleKeyDown: handleKeyDown
        }), null, 16);
      }), 128))])], 4)], 6)) : _createCommentVNode("", true);
    };
  }
});